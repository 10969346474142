import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  CircularProgress,
  useTheme,
  styled,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  DirectionsBike as DirectionsBikeIcon,
  LocalOffer as LocalOfferIcon,
  Groups as GroupsIcon,
  Build as BuildIcon,
  Navigation as NavigationIcon,
  CalendarToday as CalendarTodayIcon,
  Settings as SettingsIcon,
  CardGiftcard,
  GroupAdd,
  HelpOutline,
  Security,
  Translate,
  QrCode2,
} from "@mui/icons-material";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import ParkIcon from "@mui/icons-material/Park";
import TagCheck from "./TagCheck";
import WalletIcon from "@mui/icons-material/Wallet";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  padding: theme.spacing(1.5, 3),
  textTransform: "none",
  fontWeight: 600,
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  width: "100%",
  textAlign: "left",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  fontSize: "0.8rem",
  padding: theme.spacing(0.5, 1),
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
  },
}));

const routes = [
  { label: "Editar Perfil", path: "/addProfile", icon: <AccountCircleIcon /> },
  { label: "BikeGarage", path: "/MyBikes", icon: <DirectionsBikeIcon /> },
  { label: "Mis Tags", path: "/MyTags", icon: <LocalOfferIcon /> },
  { label: "Mis Equipos", path: "/MyTeams", icon: <GroupsIcon /> },
  { label: "Mis WorkShops", path: "/myworkshop", icon: <BuildIcon /> },
  { label: "Live Location", path: "/livelocation", icon: <NavigationIcon /> },
  { label: "Mis eventos", path: "/my-events", icon: <CalendarTodayIcon /> },
  { label: "Mis Tickets", path: "/tickets", icon: <QrCode2 /> },
];

const UserMenu = () => {
  const { user, loading } = useUserAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (!loading && !user) {
      navigate("/login");
    } else if (user) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });

      return () => unsubscribe();
    }
  }, [user, loading, navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            src={userInfo?.photoUrl || "https://via.placeholder.com/40"}
            alt={userInfo?.name || "User Name"}
            sx={{
              width: 50,
              height: 50,
              mr: 2,
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Hola, {userInfo?.name || "User"}
            </Typography>
            <ProfileButton
              component={Link}
              to={`/user/${user?.uid}`}
              variant="contained"
              sx={{ mt: 1 }}
            >
              Ver perfil
            </ProfileButton>
          </Box>
        </Box>
        <IconButton
          sx={{ color: theme.palette.text.primary }}
          component={Link}
          to="/addProfile"
        >
          <SettingsIcon />
        </IconButton>
      </Box>

      {/* Botones principales */}
      <Box>
        {routes.map((route, index) => (
          <MenuButton
            key={index}
            component={Link}
            to={route.path}
            startIcon={route.icon}
            color="inherit"
          >
            {route.label}
          </MenuButton>
        ))}
      </Box>

      {/* Sección de Créditos */}
      <Box mt={4}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          SpeedCoins
        </Typography>
        <MenuButton
          onClick={() => handleNavigation("/wallet")}
          startIcon={<WalletIcon />}
          color="inherit"
        >
          Wallet
        </MenuButton>
        <MenuButton
          onClick={() => handleNavigation("/mygiftcards")}
          startIcon={<CardGiftcard />}
          color="inherit"
        >
          Mis GiftCards
        </MenuButton>
      </Box>

      {/* Sección de Management */}
      <Box mt={4}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Gestión
        </Typography>
        <MenuButton
          onClick={() => handleNavigation("/misreceivers")}
          startIcon={<TapAndPlayIcon />}
          color="inherit"
        >
          Mis Receivers
        </MenuButton>
        <MenuButton
          onClick={() => handleNavigation("/security-help")}
          startIcon={<ParkIcon />}
          color="inherit"
        >
          Mis Parques
        </MenuButton>
        {userInfo?.type?.includes("eventManager") && (
          <MenuButton
            onClick={() => handleNavigation("/miscomites")}
            startIcon={<CalendarTodayIcon />}
            color="inherit"
          >
            Mis Comites
          </MenuButton>
        )}
      </Box>

      {/* Sección de Asistencia */}
      <Box mt={4}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Asistencia
        </Typography>
        <MenuButton
          onClick={() => handleNavigation("/documentacion")}
          startIcon={<HelpOutline />}
          color="inherit"
        >
          Documentación
        </MenuButton>
        <MenuButton
          onClick={() => handleNavigation("/verificaciones")}
          startIcon={<Security />}
          color="inherit"
        >
          Verificaciones
        </MenuButton>
      </Box>

      {/* Idioma y moneda */}
      <Box mt={4} display="flex" justifyContent="space-between">
        <StyledButton startIcon={<Translate />} variant="outlined">
          Español (MX)
        </StyledButton>
        <StyledButton variant="outlined">$ MXN</StyledButton>
      </Box>
      <TagCheck />
    </Box>
  );
};

export default UserMenu;
