import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Avatar,
  CircularProgress,
  AppBar,
  Toolbar,
  Paper,
  Fade,
  Slide,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";

// Load bubble sound
const bubbleSound = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FPop%20Bubble%20Sound%20Effect%202022.mp3?alt=media&token=632363d4-0c77-4229-93fa-513b0749879e"
);

bubbleSound.onerror = () => {
  console.log("Error: No se pudo cargar el sonido de burbuja.");
};

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "80%",
  wordBreak: "break-word",
  borderRadius: 12,
}));

const UserMessage = styled(StyledPaper)(({ theme }) => ({
  backgroundColor: "black",
  color: theme.palette.primary.contrastText,
  alignSelf: "flex-end",
}));

const AssistantMessage = styled(StyledPaper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.text.primary,
  alignSelf: "flex-start",
}));

const MessageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

const QuickPromptButton = styled(Button)({
  backgroundColor: "#000",
  color: "#fff",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#333",
  },
  padding: "8px 16px",
});

const FloatingMessage = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: { xs: 120, md: 155 },

  left: { xs: 20, md: 30 },
  padding: theme.spacing(2),
  backgroundColor: "black",
  color: "white",
  borderRadius: 12,
  zIndex: 1000,
  maxWidth: 200,
  animation: "fadeInOut 4s forwards",
  "@keyframes fadeInOut": {
    "0%": { opacity: 0, transform: "translateY(20px)" },
    "10%": { opacity: 1, transform: "translateY(0)" },
    "90%": { opacity: 1, transform: "translateY(0)" },
    "100%": { opacity: 0, transform: "translateY(20px)" },
  },
}));

const FloatingSupportButton = () => {
  const { user } = useUserAuth();
  const [openChat, setOpenChat] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPhotoURL, setUserPhotoURL] = useState("");
  const [showQuickPrompts, setShowQuickPrompts] = useState(true);
  const [showFloatingMessage, setShowFloatingMessage] = useState(false);
  const chatEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.uid) {
      const userDocRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setUserName(userData.name || "Usuario");
          setUserPhotoURL(userData.photoUrl || "/path_to_default_avatar.jpg");
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const playBubbleSound = () => {
    try {
      bubbleSound.play();
    } catch (error) {
      console.error("Error al reproducir el sonido de burbuja:", error);
    }
  };

  const handleNavigateAndClose = (route) => {
    setOpenChat(false);
    setShowFloatingMessage(true);
    navigate(route);
    setTimeout(() => {
      setShowFloatingMessage(false);
    }, 4000);
  };

  const handleSendMessage = async (promptMessage) => {
    const userMessageContent = promptMessage || message.trim();

    if (userMessageContent === "" || isLoading) return;

    setIsLoading(true);
    setIsTyping(false);

    const userMessage = {
      role: "user",
      content: userMessageContent,
      name: userName,
      avatar: userPhotoURL,
    };

    setChatHistory((prev) => [...prev, userMessage]);
    setMessage("");
    setShowQuickPrompts(false);

    try {
      if (isConnecting) {
        const connectingMessage = {
          role: "system",
          content:
            "Te presentamos a Speedy la nueva inteligencia artificial de Livespeed!",
          name: "Livespeed",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
        };
        setChatHistory((prev) => [...prev, connectingMessage]);
        setIsConnecting(false);
      }

      setIsTyping(true);

      const response = await axios.post(
        "https://heroic-truck-437503-j4.wl.r.appspot.com/sendMessageToAssistant",
        {
          message: userMessageContent,
        }
      );

      let assistantContent = response.data.response;

      if (Array.isArray(assistantContent)) {
        assistantContent = assistantContent
          .filter((item) => item.type === "text")
          .map((item) => item.text?.value)
          .join(" ");
      }

      const linkyPattern = /livespeed.mx\/(\S+)/g;
      const match = linkyPattern.exec(assistantContent);

      const assistantMessage = {
        role: "assistant",
        content: assistantContent,
        name: "Speedy AI",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FDALL%C2%B7E%202024-10-02%2020.15.08%20-%20An%20animated%20logo%20for%20Speedy%2C%20a%20chatbot%20expert%20in%20bicycles.%20The%20logo%20should%20feature%20a%20friendly%20chatbot%20character%20with%20bicycle%20elements%20incorporated%20int.png?alt=media&token=d8fd5f7a-7f96-4e0e-9c7d-88ec36e60dd6",
        hasLink: match ? true : false,
        linkRoute: match ? match[1] : null,
      };

      setIsTyping(false);
      setChatHistory((prev) => [...prev, assistantMessage]);
      playBubbleSound();
    } catch (error) {
      console.error("Error al comunicarse con el asistente:", error);
      const errorMessage = {
        role: "assistant",
        content: "Error al comunicarse con el asistente. Inténtalo de nuevo.",
        name: "Error",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Fservicio-al-cliente.png?alt=media&token=74a19077-5e1c-43a5-b1dc-5bdf778d2842",
      };
      setIsTyping(false);
      setChatHistory((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  // Quick Prompts: Estos solo escribirán en el chat sin redirigir
  const QuickPrompts = () =>
    showQuickPrompts && (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          mt: 2,
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {[
          "¿Cómo vinculo un brazalete?",
          "¿Cómo invito a mis amigos?",
          "¿Dónde veo mis tiempos?",
        ].map((prompt, index) => (
          <QuickPromptButton
            key={index}
            onClick={() => handleSendMessage(prompt)}
          >
            {prompt}
          </QuickPromptButton>
        ))}
      </Box>
    );

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, isTyping]);

  useEffect(() => {
    if (!chatHistory.length) {
      const welcomeMessage = {
        role: "system",
        content: "Necesitas ayuda? ¡Pregúntale a Speedy!",
        name: "Speedy AI",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
      };
      setChatHistory([welcomeMessage]);
    }
  }, [chatHistory]);

  return (
    <>
      <Tooltip title="Soporte" arrow>
        <IconButton
          onClick={() => setOpenChat(true)}
          sx={{
            position: "fixed",
            bottom: { xs: 80, md: 115 },
            left: { xs: 20, md: 30 },
            backgroundColor: "#000000",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#333333",
            },
            boxShadow: 3,
            width: 56,
            height: 56,
            zIndex: 1000,
          }}
        >
          <SupportAgentIcon sx={{ fontSize: 32 }} />
        </IconButton>
      </Tooltip>

      {showFloatingMessage && (
        <FloatingMessage>
          <Typography variant="body2">
            ¡Cualquier duda aquí sigo en linea!
          </Typography>
        </FloatingMessage>
      )}

      <Dialog
        open={openChat}
        onClose={() => setOpenChat(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 2,
            height: "85%",
          },
        }}
      >
        <AppBar position="relative" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Chat de Soporte
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpenChat(false)}
              aria-label="cerrar"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            bgcolor: "grey.100",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              mb: 2,
              bgcolor: "background.paper",
              borderRadius: 1,
              p: 2,
            }}
          >
            <MessageContainer>
              {chatHistory.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: msg.role === "user" ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection:
                        msg.role === "user" ? "row-reverse" : "row",
                      gap: 1,
                      mb: 0.5,
                    }}
                  >
                    <Avatar src={msg.avatar} />
                    <Typography variant="caption" color="text.secondary">
                      {msg.name}
                    </Typography>
                  </Box>
                  {msg.role === "user" ? (
                    <UserMessage>
                      <Typography>{msg.content}</Typography>
                    </UserMessage>
                  ) : (
                    <>
                      <AssistantMessage>
                        <Typography>{msg.content}</Typography>
                      </AssistantMessage>
                      {msg.hasLink && (
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleNavigateAndClose(`/${msg.linkRoute}`)
                          }
                          sx={{
                            mt: 1,
                            borderRadius: 2,
                            backgroundColor: "#000",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#333",
                            },
                          }}
                        >
                          Ir a {msg.linkRoute}
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              ))}
              {isTyping && (
                <Fade in={isTyping}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FDALL%C2%B7E%202024-10-02%2020.15.08%20-%20An%20animated%20logo%20for%20Speedy%2C%20a%20chatbot%20expert%20in%20bicycles.%20The%20logo%20should%20feature%20a%20friendly%20chatbot%20character%20with%20bicycle%20elements%20incorporated%20int.png?alt=media&token=d8fd5f7a-7f96-4e0e-9c7d-88ec36e60dd6"
                      sx={{
                        animation: "spin 1s linear infinite",
                        "@keyframes spin": {
                          "0%": {
                            transform: "rotate(0deg)",
                          },
                          "50%": {
                            transform: "rotate(15deg)",
                          },
                          "100%": {
                            transform: "rotate(-15deg)",
                          },
                        },
                      }}
                    />
                    <Slide direction="right" in={isTyping}>
                      <Typography variant="body2" color="text.secondary">
                        Speedy está escribiendo...
                      </Typography>
                    </Slide>
                  </Box>
                </Fade>
              )}
              <div ref={chatEndRef} />
            </MessageContainer>
          </Box>

          <QuickPrompts />

          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe tu mensaje..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={isLoading}
              sx={{
                bgcolor: "background.paper",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
            <Button
              variant="contained"
              onClick={() => handleSendMessage()}
              disabled={isLoading}
              sx={{
                bgcolor: "#000000",
                "&:hover": {
                  bgcolor: "#333333",
                },
                borderRadius: 2,
                minWidth: 56,
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <SendIcon />
              )}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingSupportButton;
