import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Typography,
  Container,
  Grid,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Checkbox,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

// Función para convertir Timestamps de Firestore a una cadena legible
const convertTimestampToDate = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return "N/A"; // Si el timestamp es null o no tiene `seconds`, retorna "N/A"
  const date = new Date(timestamp.seconds * 1000); // Convertir de segundos a milisegundos
  return date.toLocaleString(); // Convertir la fecha a un formato legible
};

const RunManager = () => {
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRuns, setSelectedRuns] = useState([]); // Para seleccionar múltiples runs
  const [openDialog, setOpenDialog] = useState(false);
  const [runToDelete, setRunToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRuns = async () => {
      try {
        // Obtener todas las runs
        const runsSnapshot = await getDocs(collection(db, "Runs"));
        const runsData = runsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Procesar cada run para obtener el nombre del usuario desde "Tags" y "Users"
        const processedRuns = await Promise.all(
          runsData.map(async (run) => {
            // Consulta para obtener el tag correspondiente al NFCIdentifier
            const tagQuery = query(
              collection(db, "Tags"),
              where("NFCID", "==", run.NFCIdentifier)
            );
            const tagSnapshot = await getDocs(tagQuery);

            let riderName = "Desconocido";
            if (!tagSnapshot.empty) {
              const ownerUID = tagSnapshot.docs[0].data().Owner;

              // Consulta para obtener el nombre del usuario desde "Users"
              const userQuery = query(
                collection(db, "Users"),
                where("uid", "==", ownerUID)
              );
              const userSnapshot = await getDocs(userQuery);

              if (!userSnapshot.empty) {
                riderName = userSnapshot.docs[0].data().name || "Desconocido";
              }
            }

            return {
              ...run,
              riderName,
            };
          })
        );

        setRuns(processedRuns);
      } catch (error) {
        console.error("Error fetching runs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRuns();
  }, []);

  // Manejo del filtrado de runs por nombre o NFC identifier
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredRuns = runs.filter(
    (run) =>
      run.riderName.toLowerCase().includes(searchTerm) ||
      run.NFCIdentifier.toLowerCase().includes(searchTerm)
  );

  const handleRunClick = (runId) => {
    navigate(`/run/${runId}`);
  };

  // Seleccionar o deseleccionar una run
  const handleSelectRun = (runId) => {
    if (selectedRuns.includes(runId)) {
      setSelectedRuns(selectedRuns.filter((id) => id !== runId));
    } else {
      setSelectedRuns([...selectedRuns, runId]);
    }
  };

  // Abrir el cuadro de diálogo de confirmación para eliminar una run
  const handleDeleteClick = (runId) => {
    setRunToDelete(runId);
    setOpenDialog(true);
  };

  // Eliminar una run
  const deleteRun = async (runId) => {
    try {
      await deleteDoc(doc(db, "Runs", runId));
      setRuns(runs.filter((run) => run.id !== runId));
      toast.success("Run eliminada correctamente");
    } catch (error) {
      toast.error("Error al eliminar la run");
    }
    setOpenDialog(false);
    setRunToDelete(null);
  };

  // Eliminar varias runs seleccionadas
  const deleteSelectedRuns = async () => {
    const deletePromises = selectedRuns.map((runId) =>
      deleteDoc(doc(db, "Runs", runId))
    );
    try {
      await Promise.all(deletePromises);
      setRuns(runs.filter((run) => !selectedRuns.includes(run.id)));
      setSelectedRuns([]);
      toast.success("Runs eliminadas correctamente");
    } catch (error) {
      toast.error("Error al eliminar las runs");
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 3, mb: 6 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Gestionar Runs
      </Typography>

      {/* Buscador */}
      <TextField
        label="Buscar por nombre o NFC Identifier"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 3 }}
      />

      {/* Botón para eliminar varias runs */}
      {selectedRuns.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={deleteSelectedRuns}
            startIcon={<DeleteIcon />}
          >
            Eliminar {selectedRuns.length} Run(s)
          </Button>
        </Box>
      )}

      {/* Lista de Runs */}
      <List>
        {filteredRuns.length === 0 ? (
          <Typography variant="body1" align="center">
            No hay runs disponibles.
          </Typography>
        ) : (
          filteredRuns.map((run) => (
            <ListItem
              key={run.id}
              sx={{ mb: 1, borderBottom: "1px solid #ddd" }}
            >
              <Checkbox
                checked={selectedRuns.includes(run.id)}
                onChange={() => handleSelectRun(run.id)}
              />
              <ListItemText
                primary={`Rider: ${run.riderName} (NFC: ${run.NFCIdentifier})`}
                secondary={
                  <>
                    <Box component="span">
                      Tiempo: {run.ElapsedTime || "N/A"}
                    </Box>{" "}
                    <br />
                    <Box component="span">
                      Inicio: {convertTimestampToDate(run.StartTime)}
                    </Box>{" "}
                    <br />
                    <Box component="span">
                      Final: {convertTimestampToDate(run.FinishTime)}
                    </Box>{" "}
                    <br />
                    <Box component="span">Track ID: {run.TrackID || "N/A"}</Box>
                  </>
                }
              />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteClick(run.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))
        )}
      </List>

      {/* Diálogo de confirmación */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Eliminar Run</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar esta run? Esta acción no se
            puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => deleteRun(runToDelete)}
            color="secondary"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RunManager;
