import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { Button, Box } from "@mui/material"; // Importación de Material UI
import EventLeaderboardUI from "./EventLeaderboardUI";
import ComparePopup from "./ComparePopup"; // Importar el popup
import PersonIcon from "@mui/icons-material/Person";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const EventLeaderboardLogic = () => {
  const { uid } = useParams();
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ownerData, setOwnerData] = useState({});
  const [isCompareOpen, setIsCompareOpen] = useState(false); // Estado para controlar el popup
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRuns = () => {
      const runsQuery = query(
        collection(db, "Runs"),
        where("EventID", "==", uid)
      );

      const unsubscribe = onSnapshot(runsQuery, async (snapshot) => {
        const runsData = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((run) => run.ElapsedTime); // Solo incluir las carreras con tiempo

        const sortedRuns = runsData.sort(
          (a, b) =>
            convertTimeToMilliseconds(a.ElapsedTime) -
            convertTimeToMilliseconds(b.ElapsedTime)
        );

        const bestTimesByUser = sortedRuns.reduce((acc, run) => {
          if (
            !acc[run.NFCIdentifier] ||
            convertTimeToMilliseconds(run.ElapsedTime) <
              convertTimeToMilliseconds(acc[run.NFCIdentifier].ElapsedTime)
          ) {
            acc[run.NFCIdentifier] = run;
          }
          return acc;
        }, {});

        const uniqueRuns = Object.values(bestTimesByUser);

        const ownerPromises = uniqueRuns.map(async (run) => {
          const tagsQuery = query(
            collection(db, "Tags"),
            where("NFCID", "==", run.NFCIdentifier)
          );
          const tagsSnapshot = await getDocs(tagsQuery);

          if (!tagsSnapshot.empty) {
            const ownerUID = tagsSnapshot.docs[0].data().Owner;
            const userQuery = query(
              collection(db, "Users"),
              where("uid", "==", ownerUID)
            );
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
              const userData = userSnapshot.docs[0].data();
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: userData.name,
                PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
              };
            }
          }
          return {
            NFCIdentifier: run.NFCIdentifier,
            Owner: "Desconocido",
            PhotoUrl: "/default-avatar.jpg",
          };
        });

        const ownerResults = await Promise.all(ownerPromises);
        const ownerDataMap = ownerResults.reduce((acc, ownerResult) => {
          acc[ownerResult.NFCIdentifier] = {
            Owner: ownerResult.Owner,
            PhotoUrl: ownerResult.PhotoUrl,
          };
          return acc;
        }, {});

        setOwnerData(ownerDataMap);
        setRuns(uniqueRuns);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchRuns();
  }, [uid]);

  const getTimeDifference = (aboveTime, currentTime) => {
    const aboveInMs = convertTimeToMilliseconds(aboveTime);
    const currentInMs = convertTimeToMilliseconds(currentTime);
    return currentInMs - aboveInMs;
  };

  const convertTimeToMilliseconds = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const [secs, ms] = seconds.split(".");
    return (
      parseInt(hours) * 3600000 +
      parseInt(minutes) * 60000 +
      parseInt(secs) * 1000 +
      parseInt(ms)
    );
  };

  const handleCardClick = (runId) => {
    navigate(`/run/${runId}`);
  };

  const handleOpenCompare = () => {
    setIsCompareOpen(true);
  };

  const handleCloseCompare = () => {
    setIsCompareOpen(false);
  };

  return (
    <div>
      {/* Botón personalizado */}
      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "30px",
            textTransform: "none",
            "&:hover": { backgroundColor: "#333" },
          }}
          onClick={handleOpenCompare}
          startIcon={<PersonIcon />}
          endIcon={<PersonIcon />}
        >
          <CompareArrowsIcon sx={{ fontSize: "24px", mx: 1 }} />
          Comparate
        </Button>
      </Box>

      {/* Popup para comparación */}
      <ComparePopup
        open={isCompareOpen}
        onClose={handleCloseCompare}
        runs={runs}
        ownerData={ownerData} // Pasamos los datos de los propietarios
        getTimeDifference={getTimeDifference}
      />

      <EventLeaderboardUI
        loading={loading}
        runs={runs}
        ownerData={ownerData}
        getTimeDifference={getTimeDifference}
        handleCardClick={handleCardClick}
      />
    </div>
  );
};

export default EventLeaderboardLogic;
