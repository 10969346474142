import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, getDoc, collection, getDocs, addDoc } from "firebase/firestore";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import {
  ShoppingCart,
  NavigateNext,
  NavigateBefore,
} from "@mui/icons-material";
import CategoryStep from "./Stepper/CategoryStep";
import AddonsStep from "./Stepper/AddonsStep";
import SummaryStep from "./Stepper/SummaryStep";
import WarningDialog from "./Stepper/WarningDialog"; // Importar WarningDialog

const steps = ["Seleccionar Categoría", "Elegir Add-ons", "Resumen y Pago"];

const PayEvent = () => {
  const { id } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [event, setEvent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonSizes, setAddonSizes] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nfcWarning, setNfcWarning] = useState(false); // Mostrar popup solo en Add-ons
  const [nfcAddon, setNfcAddon] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventDoc, addonsSnapshot, categoriesSnapshot] =
          await Promise.all([
            getDoc(doc(db, "events", id)),
            getDocs(collection(db, "events", id, "addons")),
            getDocs(collection(db, "events", id, "categories")),
          ]);

        if (!eventDoc.exists()) {
          throw new Error("Evento no encontrado");
        }

        const eventData = { id: eventDoc.id, ...eventDoc.data() };

        const nfcBracelet = {
          id: "nfc-bracelet",
          type: "Pulsera NFC",
          price: eventData.pulsera ? 0 : 50,
          imageUrl:
            "https://ftp3.syscom.mx/usuarios/fotos/BancoFotografiasSyscom/ACCESSPRO/ACCESSWB65M1K/ACCESSWB65M1K-AD-3-l.PNG",
        };

        const firebaseAddons = addonsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          imageUrl: doc.data().pic || null,
        }));

        setEvent(eventData);
        setAddons([nfcBracelet, ...firebaseAddons]);
        setCategories(
          categoriesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        // Preseleccionar la pulsera NFC si no tiene precio
        setSelectedAddons([nfcBracelet]);
        setNfcAddon(nfcBracelet);
      } catch (error) {
        setError(error.message || "Error al cargar los datos");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prev) => prev + 1);
      setNfcWarning(true); // Mostrar el popup al llegar al paso de Add-ons
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleAddonChange = (addon, isChecked) => {
    setSelectedAddons((prev) => {
      // Verificar si la pulsera NFC ya está seleccionada
      if (addon.id === "nfc-bracelet") {
        if (isChecked && !prev.some((a) => a.id === addon.id)) {
          // Si está marcada y no está seleccionada, agregarla
          return [...prev, addon];
        } else if (!isChecked) {
          // Si está desmarcada, quitarla
          return prev.filter((a) => a.id !== addon.id);
        }
        // No hacer nada si ya está seleccionada
        return prev;
      }

      // Para otros addons, agregar o quitar normalmente
      return isChecked
        ? [...prev, addon]
        : prev.filter((a) => a.id !== addon.id);
    });

    if (!isChecked) {
      const newSizes = { ...addonSizes };
      delete newSizes[addon.id];
      setAddonSizes(newSizes);
    }
  };

  const handleSizeChange = (addonId, size) => {
    setAddonSizes((prev) => ({ ...prev, [addonId]: size }));
  };

  const getTotalPrice = () => {
    const addonsTotal = selectedAddons.reduce(
      (total, addon) => total + addon.price,
      0
    );
    return (event?.price || 0) + addonsTotal;
  };

  const handlePayment = async () => {
    try {
      const orderAddons = [
        ...(event.price > 0
          ? [
              {
                type: `Entrada: ${event.eventName}`,
                price: event.price,
                size: null,
              },
            ]
          : []),
        ...selectedAddons.map((addon) => ({
          type: addon.type,
          price: addon.price,
          size: addonSizes[addon.id] || null,
        })),
      ];

      const orderData = {
        ItemUID: event.id,
        Total: getTotalPrice(),
        Status: "Pending",
        UserUID: user.uid,
        Addons: orderAddons,
        Category: selectedCategory,
        Type: "event",
      };

      const orderRef = await addDoc(collection(db, "orders"), orderData);
      navigate(`/checkout/${orderRef.id}`);
    } catch (error) {
      setError("Error al procesar el pago");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Paper sx={{ p: 3, mt: 3, textAlign: "center" }}>
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ backgroundColor: "black", color: "white" }}
          >
            Volver
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ pb: 8 }}>
      <Typography variant="h5" gutterBottom align="center" sx={{ mt: 3 }}>
        {event.eventName}
      </Typography>

      <Paper sx={{ p: 3, mt: 3 }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStepIcon-root": {
              color: "black",
              "& .MuiStepIcon-text": {
                fill: "white",
              },
            },
            "& .MuiStepLabel-label": {
              color: "black",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4 }}>
          {activeStep === 0 && (
            <CategoryStep
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
            />
          )}
          {activeStep === 1 && (
            <AddonsStep
              addons={addons}
              selectedAddons={selectedAddons}
              addonSizes={addonSizes}
              onAddonChange={handleAddonChange}
              onSizeChange={handleSizeChange}
            />
          )}
          {activeStep === 2 && (
            <SummaryStep
              event={event}
              selectedCategory={categories.find(
                (c) => c.id === selectedCategory
              )}
              selectedAddons={selectedAddons}
              addonSizes={addonSizes}
              totalPrice={getTotalPrice()}
            />
          )}
        </Box>

        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              startIcon={<NavigateBefore />}
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "50px",
                padding: "10px 20px",
                mr: 2,
              }}
            >
              Anterior
            </Button>
          )}
          <Button
            variant="contained"
            onClick={
              activeStep === steps.length - 1 ? handlePayment : handleNext
            }
            disabled={
              (activeStep === 0 && !selectedCategory) ||
              (activeStep === steps.length - 1 && getTotalPrice() === 0)
            }
            sx={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "50px",
              padding: "10px 20px",
            }}
            endIcon={
              activeStep === steps.length - 1 ? (
                <ShoppingCart />
              ) : (
                <NavigateNext />
              )
            }
          >
            {activeStep === steps.length - 1
              ? `Pagar $${getTotalPrice()}`
              : "Siguiente"}
          </Button>
        </Box>
      </Paper>

      <WarningDialog
        open={nfcWarning}
        onClose={() => setNfcWarning(false)}
        onAddonChange={handleAddonChange}
        nfcAddon={nfcAddon}
      />
    </Container>
  );
};

export default PayEvent;
