import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  Box,
  IconButton,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const AddonCard = ({ addon, selected, onChange, size, onSizeChange }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        cursor: "pointer",
        borderRadius: "15px", // Bordes redondeados
        border: selected ? "2px solid black" : "1px solid #e0e0e0",
        boxShadow: selected ? "0 4px 15px rgba(0, 0, 0, 0.2)" : "none", // Sombra suave cuando está seleccionado
        transition: "all 0.3s ease-in-out",
        ":hover": {
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)", // Sombra al pasar el mouse
        },
      }}
      onClick={() => onChange(addon, !selected)}
    >
      {addon.imageUrl && (
        <CardMedia
          component="img"
          height="200px" // Ajustamos la altura de la imagen para mejor apariencia
          image={addon.imageUrl}
          alt={`Addon ${addon.type}`}
          sx={{
            width: "100%",
            objectFit: "contain", // Mostramos la imagen completa sin recortes
            borderRadius: "15px 15px 0 0", // Bordes superiores redondeados
            backgroundColor: "#f5f5f5", // Fondo blanco para que se vea bien la imagen
          }}
        />
      )}
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
            {addon.type}
          </Typography>
          <Checkbox
            checked={selected}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircleOutline sx={{ color: "green" }} />} // Color verde cuando se selecciona
          />
        </Box>
        {addon.description && (
          <Typography variant="body2" sx={{ color: "#666", mb: 1 }}>
            {addon.description}
          </Typography>
        )}
        {addon.size && (
          <Box
            display="flex"
            justifyContent="center"
            sx={{ mt: 2, mb: 2 }} // Aumentamos el espacio para que los círculos estén bien distribuidos
          >
            {addon.size.map((sizeOption) => (
              <IconButton
                key={sizeOption}
                onClick={() => onSizeChange(addon.id, sizeOption)}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: size === sizeOption ? "black" : "#e0e0e0", // Color negro cuando está seleccionado
                  color: size === sizeOption ? "white" : "black", // Texto blanco cuando está seleccionado
                  transition: "all 0.3s ease",
                  mx: 1, // Espacio horizontal entre los círculos
                  "&:hover": {
                    backgroundColor: size === sizeOption ? "black" : "#ccc", // Efecto hover
                  },
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {sizeOption}
                </Typography>
              </IconButton>
            ))}
          </Box>
        )}
        {addon.stock && (
          <Typography variant="body2" sx={{ color: "#666", mb: 1 }}>
            Stock: {addon.stock}
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{ color: "black", fontWeight: "bold", mt: 2 }}
        >
          ${addon.price}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AddonCard;
