import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import AddonCard from "../CheckOut/AddonCard";


const AddonsStep = ({
  addons,
  selectedAddons,
  addonSizes,
  onAddonChange,
  onSizeChange,
}) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      <AddCircleOutline sx={{ mr: 1, verticalAlign: "middle" }} />
      Selecciona add-ons opcionales
    </Typography>
    <Stack spacing={2}>
      {addons.map((addon) => (
        <AddonCard
          key={addon.id}
          addon={addon}
          selected={selectedAddons.some((a) => a.id === addon.id)}
          onChange={onAddonChange}
          size={addonSizes[addon.id]}
          onSizeChange={onSizeChange}
        />
      ))}
    </Stack>

  </Box>
);

export default AddonsStep;
