import React, { useState, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { EventAvailable } from "@mui/icons-material";
import ConfettiExplosion from "react-confetti-explosion";

const CategoryStep = ({ categories, selectedCategory, onCategoryChange }) => {
  const [isExploding, setIsExploding] = useState(false);
  const [explosionPosition, setExplosionPosition] = useState({ x: 0, y: 0 });

  // Usamos useRef para las dos referencias de audio
  const explosionSoundRef = useRef(null);
  const ebikeSoundRef = useRef(null);

  const handleCategoryClick = (event, categoryId, categoryName) => {
    onCategoryChange(categoryId);

    // Obtener la posición del clic relativo a la página
    const x = event.clientX;
    const y = event.clientY;

    // Guardar la posición del clic para el confetti
    setExplosionPosition({ x, y });

    // Mostrar confetti
    setIsExploding(true);

    // Reproducir el audio adecuado
    if (categoryName.toLowerCase().includes("e-bike")) {
      ebikeSoundRef.current.play();  // Reproducir sonido de e-bike
    } else {
      explosionSoundRef.current.play();  // Reproducir sonido de fuegos artificiales
    }

    setTimeout(() => setIsExploding(false), 2200); // Desactiva el confetti después de 2.2 segundos
  };

  return (
    <Box position="relative">
      {/* Componente para el sonido de explosión de fuegos artificiales */}
      <audio
        ref={explosionSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Ffirework.mp3?alt=media&token=f7cb205d-f6a4-4833-8996-08407fdea0ce"
      />
      
      {/* Componente para el sonido de e-bike */}
      <audio
        ref={ebikeSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Fsi%20soy%20ama%20%5B%20ezmp3.cc%20%5D.mp3?alt=media&token=3072ab7f-22bd-434f-a56a-2d5333b3c17e"
      />

      {/* Componente de confetti explosion */}
      {isExploding && (
        <Box
          sx={{
            position: "absolute",
            left: explosionPosition.x,
            top: explosionPosition.y,
            transform: "translate(-50%, -50%)", // Centramos la explosión
            pointerEvents: "none", // Evita que el confetti interfiera con otros clics
            zIndex: 10,
          }}
        >
          <ConfettiExplosion
            particleCount={200}
            duration={2500}
            force={0.6}
            width={1000}
          />
        </Box>
      )}

      <Typography variant="h6" gutterBottom>
        <EventAvailable sx={{ mr: 1, verticalAlign: "middle" }} />
        Selecciona tu categoría
      </Typography>
      <Stack spacing={2}>
        {categories.map((category) => (
          <Box
            key={category.id}
            onClick={(e) => handleCategoryClick(e, category.id, category.name)}
            sx={{
              p: 2,
              border: "5px solid",
              borderColor: selectedCategory === category.id ? "black" : "#ddd",
              cursor: "pointer",
              borderRadius: "10px",
              textAlign: "center",
              transition: "0.3s",
              ":hover": {
                borderColor: "black",
              },
              position: "relative", // Aseguramos que el confetti esté en relación al botón
            }}
          >
            <Typography variant="body1">{category.name}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default CategoryStep;
