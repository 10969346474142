import React from "react";
import NewRides from "./Rides/NewRides";

import UpcomingEventsBanner from "./Events/UpcomingEventsBanner";

const Home = () => {
  return (
    <div>
      <UpcomingEventsBanner />
      <NewRides />
    </div>
  );
};

export default Home;
