import React from "react";
import { Card, CardContent, Divider, List, ListItem, ListItemText, Chip, Typography, Grid } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const SummaryStep = ({
  event,
  selectedCategory,
  selectedAddons,
  addonSizes,
  totalPrice,
}) => (
  <React.Fragment>
    <Typography variant="h6" gutterBottom align="center" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
      <CheckCircle sx={{ mr: 1, verticalAlign: "middle", fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
      Resumen:
    </Typography>
    <Card 
      variant="outlined" 
      sx={{
        mt: 1,
        p: { xs: 1.5, sm: 2.5 }, // Padding optimizado para móviles y pantallas más grandes
        borderRadius: 2,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        overflow: 'hidden',
        ':before, :after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '8px',
          background: 'repeating-linear-gradient(90deg, transparent, transparent 4px, black 4px, black 6px)',
          zIndex: 1,
        },
        ':before': { top: '-8px' }, // Bordes perforados en la parte superior
        ':after': { bottom: '-8px' }, // Bordes perforados en la parte inferior
      }}
    >
      <CardContent>
        {/* Sección del nombre del evento */}
        <Typography
          variant="h5"
          align="center"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 1,
            mb: 2,
            fontSize: { xs: '1.1rem', sm: '1.3rem' }, // Tamaños ajustados
          }}
        >
          {event.eventName}
        </Typography>
        <Divider sx={{ borderStyle: 'dotted', mb: 1, borderColor: 'black' }} />
        <List disablePadding>
          {/* Detalle del evento */}
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                      {event.eventName}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                      {selectedCategory?.name}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end">
                {event.price === 0 ? (
                  <Chip
                    label="Gratis"
                    sx={{
                      backgroundColor: '#d4edda',
                      color: 'black',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      height: '24px',
                      minWidth: '60px', // Mayor ancho mínimo para evitar cortes
                    }}
                  />
                ) : (
                  <Chip label={`$${event.price}`} color="primary" sx={{ fontSize: '0.9rem', fontWeight: 'bold', minWidth: '60px' }} />
                )}
              </Grid>
            </Grid>
          </ListItem>
          <Divider sx={{ borderStyle: 'dotted', borderColor: 'black', my: 1 }} />
          {/* Detalle de Add-ons */}
          {selectedAddons.map((addon) => (
            <React.Fragment key={addon.id}>
              <ListItem>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                          {addon.type}
                        </Typography>
                      }
                      secondary={
                        addonSizes[addon.id] && (
                          <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                            Tamaño: {addonSizes[addon.id]}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end">
                    {addon.price === 0 ? (
                      <Chip
                        label="Gratis"
                        sx={{
                          backgroundColor: '#d4edda',
                          color: 'black',
                          fontSize: '0.75rem',
                          fontWeight: 'bold',
                          height: '24px',
                          minWidth: '60px', // Mayor ancho mínimo
                        }}
                      />
                    ) : (
                      <Chip label={`$${addon.price}`} sx={{ fontSize: '0.9rem', fontWeight: 'bold', minWidth: '60px' }} />
                    )}
                  </Grid>
                </Grid>
              </ListItem>

              <Divider sx={{ borderStyle: 'dotted', borderColor: 'black', my: 1 }} />
            </React.Fragment>
          ))}
        </List>
        {/* Total */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
              Total a Pagar:
            </Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {totalPrice === 0 ? (
              <Chip
                label="Gratis"
                sx={{
                  backgroundColor: '#d4edda',
                  color: 'black',
                  fontSize: { xs: '1rem', sm: '1.2rem' },
                  fontWeight: 'bold',
                  height: '30px',
                  minWidth: '60px', // Mayor ancho mínimo para evitar corte
                }}
              />
            ) : (
              <Chip label={`$${totalPrice}`} color="primary" size="large" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, fontWeight: 'bold', minWidth: '60px' }} />
            )}
          </Grid>
        </Grid>
        <Divider sx={{ borderStyle: 'dotted', mt: 2, borderColor: 'black' }} />
      </CardContent>
    </Card>
  </React.Fragment>
);

export default SummaryStep;
