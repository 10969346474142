import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import { db } from "../../firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Tooltip,
  Grid,
  Button,
  Paper,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";

const StyledCard = styled(Card)({
  borderRadius: 16,
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
});

const CheckOut = () => {
  const { uid } = useParams(); // UID de la orden
  const [preferenceId, setPreferenceId] = useState(null);
  const [order, setOrder] = useState(null);
  const [event, setEvent] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("mercadopago");

  useEffect(() => {
    initMercadoPago("TEST-d057032f-b0eb-4f4c-803d-515fa40f81ba");

    const fetchOrder = async () => {
      setLoading(true);
      try {
        const orderDocRef = doc(db, "orders", uid);
        const orderDoc = await getDoc(orderDocRef);
        if (orderDoc.exists()) {
          const orderData = { id: orderDoc.id, ...orderDoc.data() };
          setOrder(orderData);
          if (orderData.ItemUID) {
            await fetchItem(orderData.ItemUID, orderData.Category);
          } else {
            console.error("ItemUID missing from order data");
            setError("Datos del ítem no disponibles.");
          }
          createPreference(orderData.Total, orderDoc.id);
        } else {
          console.log("No se encontró la orden");
          setError("Orden no encontrada");
        }
      } catch (error) {
        console.error("Error al obtener la orden:", error);
        setError("Error al obtener la orden. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [uid]);

  const fetchItem = async (itemUID, categoryId) => {
    if (!itemUID) {
      console.error("Error: itemUID is undefined");
      setError("Error en la carga de datos del ítem");
      return;
    }

    try {
      const itemDocRef = doc(db, "events", itemUID);
      const itemDoc = await getDoc(itemDocRef);
      if (itemDoc.exists()) {
        setEvent({ id: itemDoc.id, ...itemDoc.data() });

        // Buscar la categoría dentro de la subcolección de categorías
        const categoryCollectionRef = collection(itemDocRef, "categories");
        const categorySnapshot = await getDocs(categoryCollectionRef);
        const categoryDoc = categorySnapshot.docs.find(
          (doc) => doc.id === categoryId
        );
        setCategoryName(
          categoryDoc ? categoryDoc.data().name : "Categoría no encontrada"
        );
      } else {
        console.log("No se encontró el ítem");
        setError("Ítem no encontrado");
      }
    } catch (error) {
      console.error("Error al obtener el ítem:", error);
      setError("Error al obtener el ítem. Intente nuevamente.");
    }
  };

  const createPreference = async (total, orderId) => {
    try {
      const response = await fetch(
        "https://api.mercadopago.com/checkout/preferences",
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer TEST-4496798237143399-100215-9551dc2fdf6b61fd5239c59662c5ad04-1995863127",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: [
              {
                title: "Pago de Evento",
                quantity: 1,
                currency_id: "MXN",
                unit_price: total,
              },
            ],
            back_urls: {
              success: `https://www.livespeed.mx/success/${orderId}`,
              failure: `https://www.livespeed.mx/failure/${orderId}`,
              pending: `https://www.livespeed.mx/pending/${orderId}`,
            },
            auto_return: "approved",
            external_reference: orderId,
          }),
        }
      );
      const data = await response.json();
      if (data && data.id) {
        setPreferenceId(data.id);
      } else {
        throw new Error("No se recibió ID de preferencia del servidor");
      }
    } catch (err) {
      console.error("Error creando la preferencia:", err);
      setError("Error al crear la preferencia de pago. Intente nuevamente.");
    }
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleCopyFolio = () => {
    navigator.clipboard.writeText(order.id);
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!order || !event) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          Orden no encontrada
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: "bold" }}>
        Pagar Orden: {event.eventName}
      </Typography>

      <StyledCard sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Evento:</strong> {event.eventName}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Categoría:</strong>{" "}
            {categoryName || "Categoría no encontrada"}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Status:</strong> {order.Status || "No definido"}
          </Typography>
          <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="caption" sx={{ fontSize: "12px" }}>
              <strong>Folio:</strong> {order.id}
            </Typography>
            <Tooltip title="Copiar folio">
              <IconButton size="small" onClick={handleCopyFolio}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Resumen de Pedido
          </Typography>
          <List>
            {order.Addons?.map((addon, index) => (
              <StyledListItem key={index} divider>
                <ListItemText
                  primary={addon.type}
                  secondary={`Precio: $${addon.price}`}
                />
              </StyledListItem>
            ))}
          </List>

          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" gutterBottom>
            Método de Pago
          </Typography>
          <RadioGroup
            aria-label="payment-method"
            name="payment-method"
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <FormControlLabel
              value="mercadopago"
              control={<Radio />}
              label="MercadoPago"
            />
            <FormControlLabel
              value="speedcoins"
              control={<Radio />}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FCOINS%20(1).png?alt=media&token=6197a1b6-7594-4a91-8262-d5688fc2bc0a"
                    alt="SpeedCoins"
                    style={{
                      width: "100px",
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              }
            />
          </RadioGroup>

          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" color="text.secondary" gutterBottom>
            <strong>Total:</strong> ${order.Total.toFixed(2)}
          </Typography>
          <Box mt={2} display="flex" justifyContent="center">
            {preferenceId && selectedPaymentMethod === "mercadopago" && (
              <Wallet
                initialization={{ preferenceId }}
                customization={{
                  texts: {
                    valueProp: "smart_option",
                  },
                }}
              />
            )}
            {selectedPaymentMethod === "speedcoins" && (
              <Typography variant="body2" color="error" align="center">
                No cuentas con SpeedCoins suficientes para procesar esta orden.
                ¡Sigue compitiendo!
              </Typography>
            )}
          </Box>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default CheckOut;
