import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Estilo del Card para un mini banner promocional actualizado
const MiniBannerCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  borderRadius: 12, // Bordes más suaves
  padding: 8, // Espaciado mejorado
  margin: 8,
  backgroundColor: "#f9f9f9", // Un fondo más ligero
  color: "#333", // Texto ligeramente gris para mejor contraste
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)", // Más profundidad
  transition: "all 0.3s ease",
  alignItems: "center",
  textAlign: "center",
  width: "120px", // Ancho ligeramente mayor
  cursor: "pointer", // Indica que toda la tarjeta es clickeable
  "&:hover": {
    transform: "translateY(-4px)", // Efecto de hover más notable
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)", // Más sombra en hover
  },
});

// Botón para inscribirse actualizado con colores más llamativos
const MiniSubscribeButton = styled(Button)({
  textTransform: "none",
  fontWeight: "bold",
  marginTop: 4, // Menor separación del contenido superior
  backgroundColor: "black", // Color más llamativo
  color: "#fff",
  padding: "2px 8px", // Padding más comprimido
  fontSize: "0.6rem", // Tamaño más pequeño del texto
  borderRadius: 20, // Bordes redondeados para un look moderno
  "&:hover": {
    backgroundColor: "#ff80ab", // Color de hover acorde al principal
  },
});

const UpcomingEventsBanner = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));
        // Filtrar solo eventos futuros y ordenarlos cronológicamente
        const upcomingEvents = eventsData
          .filter((event) => event.dateTime && event.dateTime > new Date())
          .sort((a, b) => a.dateTime - b.dateTime);
        setEvents(upcomingEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        width: "100vw", // Abarca toda la ventana
        maxWidth: "100%", // Asegura que no haya restricción de ancho
        margin: 0, // Sin márgenes
        overflow: "hidden", // Evita desbordamientos
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          fontSize: "1.2rem",
          mb: 2,
          textAlign: "center",
        }}
      >
        Próximos Eventos
      </Typography>
      <Slider {...sliderSettings} style={{ width: "100vw", margin: "0 -16px" }}>
        {events.map((event) => (
          <MiniBannerCard
            key={event.id}
            onClick={() => handleEventClick(event.id)}
          >
            <CardMedia
              component="img"
              image={event.photo}
              alt={`Imagen del evento ${event.eventName}`}
              sx={{
                width: "100%",
                height: "240px", // Ajustar la altura para que sea más amigable en tarjetas pequeñas
                objectFit: "cover", // Asegura que las imágenes se vean bien en el espacio asignado
                borderRadius: 2, // Hacer que las esquinas sean suaves para que coincida con el estilo general
              }}
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 0", // Reducir el espacio interior para ajustar mejor la información
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", fontSize: "0.7rem", mb: 1 }}
              >
                {event.eventName}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                <AccessTimeIcon sx={{ mr: 0.5, fontSize: "0.8rem" }} />
                <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
                  {event.dateTime.toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </Typography>
              </Box>
              <MiniSubscribeButton
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el clic en el botón active el clic en la tarjeta
                  handleEventClick(event.id);
                }}
              >
                Inscribirme
              </MiniSubscribeButton>
            </CardContent>
          </MiniBannerCard>
        ))}
      </Slider>
    </Box>
  );
};

export default UpcomingEventsBanner;
