import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Chip,
  Grid,
  Avatar,
  Stack,
  Paper,
  useTheme,
} from "@mui/material";
import {
  CheckCircle,
  ExitToApp,
  LocationOn,
  Share,
  Payment,
  AccessTime,
  Description,
  AttachMoney,
  CalendarMonth,
  Groups,
  ContentCopy,
} from "@mui/icons-material";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";

const EventDetail = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(id, {
        categories: true,
        creator: true,
        participants: true,
      });

      if (user && eventData.participants) {
        const isParticipant = eventData.participants.some(
          (p) => p.user.uid === user.uid
        );
        setUserIsParticipant(isParticipant);
      }

      setEvent(eventData);
    };

    fetchEventData();
  }, [id, user]);

  const handleJoinEvent = async () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    try {
      await eventsApi.addParticipantToEvent(id, user.uid);
      setUserIsParticipant(true);
    } catch (error) {
      console.error("Error joining event:", error);
    }
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
    } catch (error) {
      console.error("Error leaving event:", error);
    }
  };

  const handlePaymentClick = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const handleShare = () => {
    const eventUrl = window.location.href;
    const shareData = {
      title: event.eventName,
      text: `${event.eventName}\nFecha: ${new Date(
        event.dateTime
      ).toLocaleDateString()} a las ${new Date(
        event.dateTime
      ).toLocaleTimeString()}\nCosto: ${
        event.price > 0 ? `$${event.price}` : "Gratis"
      }`,
      url: eventUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Enlace y detalles copiados al portapapeles!");
    }
  };

  if (!event) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={60} sx={{ color: "black" }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card
            sx={{
              borderRadius: 4,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
          >
            <CardMedia
              component="img"
              height="400"
              image={event.photo}
              alt={event.eventName}
              sx={{ objectFit: "contain", width: "100%" }}
            />
            <CardContent sx={{ p: 4 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ color: "black", fontWeight: 700, mb: 3 }}
              >
                {event.eventName}
              </Typography>

              <OrganizerInfo creator={event.creator} />
              <EventInfo event={event} />

              <Box sx={{ mt: 4 }}>
                <ActionButtons
                  event={event}
                  userIsParticipant={userIsParticipant}
                  onJoin={handleJoinEvent}
                  onLeave={handleLeaveEvent}
                  onPayment={handlePaymentClick}
                  onShare={handleShare}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{ p: 3, borderRadius: 4, bgcolor: "#f7f7f7" }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2, color: "black", fontWeight: 600 }}
            >
              <Groups sx={{ mr: 1, verticalAlign: "middle", color: "black" }} />
              Participantes
            </Typography>
            <EventParticipants participants={event.participants} />
          </Paper>
        </Grid>
      </Grid>

      <RegisterDialog
        open={showRegisterWarning}
        onClose={() => setShowRegisterWarning(false)}
        onLogin={() => navigate("/login")}
        onSignup={() => navigate("/signup")}
      />
    </Container>
  );
};

const OrganizerInfo = ({ creator }) => (
  <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
    <Avatar
      src={creator?.photoUrl}
      alt={creator?.name}
      sx={{ width: 40, height: 40 }}
    />
    <Box>
      <Typography variant="body2" color="text.secondary">
        Organizado por
      </Typography>
      <Typography variant="subtitle1" sx={{ color: "black", fontWeight: 500 }}>
        {creator?.name || "Organizador"}
      </Typography>
    </Box>
  </Stack>
);

const EventInfo = ({ event }) => {
  const InfoItem = ({ icon: Icon, label, value }) => (
    <Grid item xs={12} sm={6}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Icon sx={{ color: "black" }} />
        <Box>
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
          <Typography variant="body1" sx={{ color: "black", fontWeight: 500 }}>
            {value}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <InfoItem
        icon={CalendarMonth}
        label="Fecha"
        value={new Date(event.dateTime).toLocaleDateString()}
      />
      <InfoItem
        icon={AccessTime}
        label="Hora"
        value={new Date(event.dateTime).toLocaleTimeString()}
      />
      <InfoItem icon={LocationOn} label="Ubicación" value="Ver en mapa" />
      {event.price > 0 && (
        <InfoItem
          icon={AttachMoney}
          label="Precio"
          value={
            <Chip
              label={`$${event.price}`}
              sx={{
                bgcolor: "black",
                color: "white",
                fontWeight: 600,
              }}
            />
          }
        />
      )}
      <Grid item xs={12}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Descripción
          </Typography>
          <Typography variant="body1" sx={{ color: "black" }}>
            {event.description}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const ActionButtons = ({
  event,
  userIsParticipant,
  onJoin,
  onLeave,
  onPayment,
  onShare,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Button
        fullWidth
        onClick={
          event.price > 0 ? onPayment : userIsParticipant ? onLeave : onJoin
        }
        startIcon={
          event.price > 0 ? (
            <Payment />
          ) : userIsParticipant ? (
            <ExitToApp />
          ) : (
            <CheckCircle />
          )
        }
        variant="contained"
        size="large"
        sx={{
          py: 1.5,
          bgcolor:
            event.price > 0 ? "black" : userIsParticipant ? "#d32f2f" : "black",
          color: "white",
          "&:hover": {
            bgcolor:
              event.price > 0 ? "#333" : userIsParticipant ? "#b71c1c" : "#333",
          },
          fontWeight: 600,
        }}
      >
        {event.price > 0
          ? "Pagar Inscripción"
          : userIsParticipant
          ? "Salir del Evento"
          : "Unirse al Evento"}
      </Button>
    </Grid>
    <Grid item xs={6}>
      <Button
        fullWidth
        startIcon={<LocationOn />}
        variant="outlined"
        sx={{
          color: "black",
          borderColor: "black",
          "&:hover": {
            borderColor: "#333",
            bgcolor: "rgba(0,0,0,0.04)",
          },
        }}
        onClick={() =>
          window.open(
            `https://www.google.com/maps?q=${event.location.lat},${event.location.lng}`
          )
        }
      >
        Ver Ubicación
      </Button>
    </Grid>
    <Grid item xs={6}>
      <Button
        fullWidth
        startIcon={<Share />}
        variant="outlined"
        sx={{
          color: "black",
          borderColor: "black",
          "&:hover": {
            borderColor: "#333",
            bgcolor: "rgba(0,0,0,0.04)",
          },
        }}
        onClick={onShare}
      >
        Compartir
      </Button>
    </Grid>
  </Grid>
);

const RegisterDialog = ({ open, onClose, onLogin, onSignup }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: 2,
        p: 1,
      },
    }}
  >
    <DialogTitle sx={{ color: "black", fontWeight: 600 }}>
      ¡Necesitas una cuenta!
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ color: "text.secondary" }}>
        Para unirte a este evento, primero debes iniciar sesión o crear una
        cuenta.
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ p: 2 }}>
      <Button
        onClick={onSignup}
        variant="outlined"
        sx={{
          color: "black",
          borderColor: "black",
          "&:hover": {
            borderColor: "#333",
            bgcolor: "rgba(0,0,0,0.04)",
          },
        }}
      >
        Registrarse
      </Button>
      <Button
        onClick={onLogin}
        variant="contained"
        sx={{
          bgcolor: "black",
          "&:hover": {
            bgcolor: "#333",
          },
        }}
      >
        Iniciar Sesión
      </Button>
    </DialogActions>
  </Dialog>
);

export default EventDetail;
