import React, { useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
} from "@mui/material";
import SpeedGiftCardList from "./SpeedGiftCardList";

const SpeedGiftCard = () => {
  const { user } = useUserAuth();
  const [amount, setAmount] = useState(0);
  const [managerUID, setManagerUID] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateGiftCard = async () => {
    if (!user) {
      setErrorMessage("Usuario no autenticado. Por favor, inicia sesión.");
      return;
    }

    if (!user.type?.includes("admin")) {
      setErrorMessage("No tienes permisos para crear una giftcard.");
      return;
    }

    if (amount <= 0) {
      setErrorMessage("La cantidad debe ser mayor que cero.");
      return;
    }

    if (managerUID.trim() === "") {
      setErrorMessage("El UID del manager no puede estar vacío.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      await addDoc(collection(db, "Giftcards"), {
        amount: amount,
        active: true,
        timestamp: Timestamp.now(),
        redeemtime: null,
        creatorUID: user.uid,
        managerUID: managerUID,
      });
      setSuccessMessage("Giftcard creada exitosamente.");
      setAmount(0);
      setManagerUID("");
    } catch (error) {
      console.error("Error al crear la giftcard:", error);
      setErrorMessage(
        "Error al crear la giftcard. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 4, mt: 5 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Crear GiftCard de SpeedCoins
        </Typography>
        <Box my={3}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            label="Cantidad de SpeedCoins"
            type="number"
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            sx={{ mb: 3, width: "100%" }}
          />
          <TextField
            label="UID del Manager"
            type="text"
            variant="outlined"
            value={managerUID}
            onChange={(e) => setManagerUID(e.target.value)}
            sx={{ mb: 3, width: "100%" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGiftCard}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Crear GiftCard"}
          </Button>
        </Box>
      </Paper>
      <SpeedGiftCardList />
    </Container>
  );
};

export default SpeedGiftCard;
