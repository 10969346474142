import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WatchIcon from "@mui/icons-material/Watch";
import EventIcon from "@mui/icons-material/Event";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const WarningDialog = ({ open, onClose, onAddonChange, nfcAddon }) => {
  const [useBorrowed, setUseBorrowed] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleBuyBracelet = () => {
    onAddonChange(nfcAddon, true);
    handleClose();
  };

  const handleUseBorrowedBracelet = () => {
    setUseBorrowed(true);
    onAddonChange(nfcAddon, false); // Deseleccionar la pulsera
  };

  const handleAgree = () => {
    handleClose(); // Cierra la ventana
  };

  return (
    <Modal 
      open={open} 
      onClose={handleClose}
      aria-labelledby="nfc-bracelet-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 350 },
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          overflowY: 'auto',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>

        {useBorrowed ? (
          <>
            <Typography variant="body2" sx={{ color: 'red', mb: 2, fontWeight: 'bold' }}>
              Recuerda: al finalizar la competencia, deberás devolverla para que tus resultados se reflejen en los leaderboards.
              Al no tener una pulsera, tus resultados no quedarán registrados en tu perfil de corredor de Livespeed. 
              Solo aparecerás en los leaderboards, pero no podrás visualizarlos en tu perfil.
            </Typography>
            <Typography variant="body2" sx={{ color: 'black', mb: 2 }}>
              No te quedes atrás y adquiere tu pulsera para llevar tu perfil al siguiente nivel.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={handleAgree}
              sx={{
                py: 1.5,
                textTransform: 'none',
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Estoy de Acuerdo
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2" sx={{ 
              textAlign: 'center',
              fontWeight: 'bold',
              mb: 2,
            }}>
              ¡Mejora tu experiencia con la Pulsera NFC!
            </Typography>

            <Stack spacing={1.5}>
              <Paper elevation={1} sx={{ p: 1.5 }}>
                <Stack direction="row" spacing={1.5} alignItems="flex-start">
                  <WatchIcon sx={{ color: 'primary.main', flexShrink: 0 }} />
                  <Typography variant="body2">
                    La pulsera se utiliza como tu identificador durante la carrera.
                  </Typography>
                </Stack>
              </Paper>

              <Paper elevation={1} sx={{ p: 1.5 }}>
                <Stack direction="row" spacing={1.5} alignItems="flex-start">
                  <LocalOfferIcon sx={{ color: 'primary.main', flexShrink: 0 }} />
                  <Typography variant="body2">
                    <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                      ¡Oferta especial!
                    </Box>{' '}
                    Cómprala por solo $50 MXN y lleva tu perfil de corredor al siguiente nivel. Al adquirir tu pulsera, podrás visualizar tus tiempos en tu perfil de Livespeed, ya que estará ligada a tu cuenta. Además, la podrás utilizar en todos los próximos eventos del Serial.
                  </Typography>
                </Stack>
              </Paper>

              <Paper elevation={1} sx={{ p: 1.5 }}>
                <Stack direction="row" spacing={1.5} alignItems="flex-start">
                  <PaymentIcon sx={{ color: 'primary.main', flexShrink: 0 }} />
                  <Typography variant="body2">
                    <Box component="span" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                      ¡Cashless!
                    </Box>{' '}
                    Al adquirir tu pulsera la puedes utilizar para pagar en diferentes establecimientos dentro del evento.
                  </Typography>
                </Stack>
              </Paper>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 3 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleBuyBracelet}
                sx={{
                  py: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Agregar Pulsera
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleUseBorrowedBracelet}
                sx={{
                  py: 1,
                  textTransform: 'none',
                }}
              >
                Usar Pulsera Prestada
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default WarningDialog;
